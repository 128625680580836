// costom select
import '../lib/jcf/jcf';
import '../lib/jcf/jcf.select';
import '../lib/jcf/jcf.scrollable';
import $ from "jquery";

export default ()=>{
  jcf.setOptions('Select', {
    wrapNative: false,
    wrapNativeOnMobile: false,
    flipDropToFit: false,
    fakeDropInBody: false,
    useCustomScroll: true,
    maxVisibleItems: 4
  });

  var $select = $("select");

  for (var i = 0; i < $select.length; i++) {
    var $item = $($select[i]);
    if (!$item.hasClass('jcf-ignore')) {
      jcf.replace($item);
    }
  }
}
