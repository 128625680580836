// magellan
import $ from "jquery";

export default function (){
  if ($('.magellan_item > a.active').length) {
  var magellanItem = $('.magellan_item > a.active + .magellan_item__submenu a');

  $(magellanItem).each(function() {
    var self = $(this);
    var magellanItemHref = self.attr('href');
    var magellanItemArray = magellanItemHref.split('#');
    var magellanItemTarget = magellanItemArray[1];
    self.attr("href", '#' + magellanItemTarget)
  });
}
}