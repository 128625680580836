import $ from 'jquery';
import 'imports-loader?jQuery=jquery!./lib/jquery.swipebox';
import 'imports-loader?jQuery=jquery!./lib/jquery.countTo.js';
import whatInput from 'what-input';
import Isotope from 'isotope-layout';
import packery from 'isotope-packery';
import AOS from 'aos';
import slick from 'slick-carousel';

import initResponsiveTable from './init/responsiveTable';
import initJfc from './init/jfc';
// import initGMap from './init/gmap';
import initEqualizer from './init/equalizer';
import initOnScroll from './init/onScroll';
import initMagellan from './init/magellan';
import initIsotope from './init/isotope';
import customFormValidation from './lib/custom-form-validation';
/*
 * Video.js
 * https://videojs.com/
 */
import videojs from 'video.js';


// import 'slick-carousel';

// var hljs = require('./lib/hljs.trimmed');
// hljs.initHighlightingOnLoad();

window.$ = $;
// window.initMap = initGMap;

AOS.init({
    easing: 'ease-out-quad',
    duration: 500
});
// import Foundation from 'foundation-sites';

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

// components libs

// cookie layer
import './lib/cookieconsent.min';

// costom inputfile
import './lib/custom-file-input';


initJfc();
initResponsiveTable();
initEqualizer();
initOnScroll();
initIsotope();
$(document).foundation();
initMagellan();

customFormValidation(['#contact']);
// Misc

$(".fce-slick").slick();
// $(".fce-slick:not(.twe-slider)").slick();
// foreach slicks
// $(".twe-slick .fce-slick").slick({
//     prevArrow: $('.twe-slick .slick-prev'),
//     nextArrow: $('.twe-slick .slick-next')
// });

// swipebox
const $swipebox = $('.swipebox');
if ($swipebox.length) {
    $swipebox.swipebox({
        selector: '.swipebox'
    });
}
//Count To
const $pTimer = $('p.timer');
if ($pTimer.length) {
    var windowHeight = jQuery(window).height();
    $pTimer.each(function () {
        var $this = $(this);
        var positionAnimation = $this.offset().top - $(window).scrollTop();
        if (windowHeight >= (positionAnimation + 90)) {
            $this.countTo({
                refreshInterval: 10
            });
            $this.data("state", "true");
        } else {
            jQuery(window).scroll(function () {
                var positionAnimation = $this.offset().top - $(window).scrollTop();
                var state = $this.data("state");
                if (windowHeight >= (positionAnimation + 90) && !state) {
                    $this.countTo({
                        refreshInterval: 10
                    });
                    $this.data("state", "true");
                }
            });
        }
    });
}

// smooth-scroll
$('a[href^="#"].smooth').click(function () {
    var href = $.attr(this, 'href');
    window.location.hash = href;
    return false;
});

// var locationHash = location.hash;
// var offset = '70'
// if (locationHash.length) {
//     $('html, body').scrollTop(0);
//     $('html,body').animate({
//         scrollTop: $(location.hash).offset().top - offset
//     }, 500);
// }
// smooth-scroll


// add blurring class to body when off-canvas menu is opened
$(document).on('opened.zf.offCanvas', function() {
    $('body').addClass('menu-canvas-opened blurring');
});
$(document).on('close.zf.offCanvas', function() {
    $('body').removeClass('menu-canvas-opened blurring');
});


/**
 * Function to get more news
 * @param container
 */
function loadMoreNews(container) {
    let startIndex = 0; // First index to start loading from
    const requestData = {
        startIndex: startIndex
    };
    $.ajax({
        url: 'twoja-url-endpointu-ajax',
        method: 'GET',
        data: requestData,
        success: function(response) {
            $(container).append(response);
            // Increment the start index
            startIndex += 3;
        },
        error: function() {
            console.log('Wystąpił błąd podczas pobierania danych.');
        }
    });
}

$(document).ready(function() {
    setTimeout(function(){
        // Find all li elements with class js-drilldown-back (button back)
         $('.twe-menu-top-wrap .js-drilldown-back').each(function() {
             // Get the text from the parent of this element
             var parentText = $(this).parent().prev().text().trim();
             // Set the text of this element to the text of the parent
             $(this).find('a').text(parentText);
         });
    }, 1000);


    // Find all .card-imglinks--autohidden containers
    $('.card-imglinks--autohidden').each(function() {
        var container = $(this);
        var btnLabelShow = container.attr('data-btn-label-show') || 'Zeige alle Nadelvliese';
        var btnLabelHidden = container.attr('data-btn-label-hidden') || 'Zeige weniger Nadelvliese';

        // Add the 'Show more' button after the .card-imglinks-items container
        container.find('.card-imglinks-items').after('<div class="text_text">\n' +
            '<p class="text-center">\n' +
            '<button type="button" class="show-more-btn bt-primary-margin">'+btnLabelShow+'</button>\n' +
            '</p>\n' +
            '</div>');

        var initialNumToShow = container.hasClass('card-imglinks--4') ? 4 : 3;
        var hiddenMoreClicked = false; // Flag to track if "Hidden more" button was clicked

        // Show/hide items based on initialNumToShow
        container.find('.card-imglink').each(function(index) {
            if (index >= initialNumToShow) {
                $(this).hide();
            }
        });

        // Add click event to the 'Show more' button
        container.find('.show-more-btn').on('click', function() {
            var button = $(this);

            // Toggle visibility of items except for the first row
            container.find('.card-imglink').each(function(index) {
                if (index >= initialNumToShow) {
                    $(this).toggle();
                }
            });

            // Change button text
            if (hiddenMoreClicked) {
                button.text(btnLabelShow);
                hiddenMoreClicked = false;
            } else {
                button.text(btnLabelHidden);
                hiddenMoreClicked = true;
            }
        });
    });

    // news related
    $('.news-related').each(function() {
        const container = $(this).find('ul');
        // var startIndex = 0; // First index to start loading from
        // var numToShow = 3; // Count of elements to load each time

        $(this).find('.load-more-btn').on('click', function() {
            // Load more news
            loadMoreNews(container);
        });
    });


    /** Zertifikate filter dropdown */
    $('.list-files').each(function() {
        var $listFiles = $(this);
        var $btnFilter = $listFiles.find('.btn-filter');
        var $dropdownItems = $listFiles.find('.dropdown-items');
        var $listItems = $listFiles.find('.list-item');

        // Handle click on filter button w danej sekcji
        $btnFilter.click(function(){
            $dropdownItems.slideToggle('slow');
        });

        // Handle click on dropdown item w danej sekcji
        $dropdownItems.find('.item').click(function(){
            var selectedType = $(this).data('value');

            // If selected option is "Alle Standorte", show all items
            if(selectedType === '') {
                $listItems.slideDown();
            } else {
                // Otherwise, hide all items and show only items with selected type
                $listItems.slideUp().filter('[data-type="' + selectedType + '"]').slideDown();
            }

            // Update filter button text
            $btnFilter.text($(this).text());

            // Hide dropdown
            $dropdownItems.slideUp();
        });
    });


    /** Lightbox Card **/
    // Open lightbox
    function openLightbox($lightboxContent) {
        $lightboxContent.addClass('active');
        $('body').append('<div class="lightbox-overlay"></div>');
    }

    // Close lightbox
    function closeLightbox($lightboxContent) {
        $lightboxContent.removeClass('active');
        $('.lightbox-overlay').remove();
    }

    // Handle click on lightbox open button
    $('.card-lightbox-open').on('click', function() {
        var $lightboxContent = $(this).closest('.card-lightbox-item').find('.card-lightbox-content');
        openLightbox($lightboxContent);
    });

    // Handle click on lightbox close button
    $('.card-lightbox-close').on('click', function() {
        var $lightboxContent = $(this).closest('.card-lightbox-content');
        closeLightbox($lightboxContent);
    });

    // Handle click on lightbox overlay
    $(document).on('click', '.lightbox-overlay', function() {
        var $lightboxContent = $('.card-lightbox-content.active');
        closeLightbox($lightboxContent);
    });

    /** search toggle icon **/
    $('#search-form').on('show.zf.dropdown', function() {
        $('[data-toggle="search-form"]').find('.icon-search').removeClass('icon-search').addClass('icon-close');
    }).on('hide.zf.dropdown', function() {
        $('[data-toggle="search-form"]').find('.icon-close').removeClass('icon-close').addClass('icon-search');
    });
});
