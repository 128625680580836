import $ from "jquery";

import './parsley/parsley';
import './parsley/i18n/de';

const init = (formSelectors)=> {
  if (!formSelectors) {return;}

  for(let i=0, len=formSelectors.length; i < len; i++) {

    let $form = $(formSelectors[i]);

    if (!$form.length) break;

    $form.parsley().subscribe('parsley:field:validated', function (fieldInstance) {
      if ($form.parsley().isValid())
        $form.addClass('parsley-valid').removeClass('parsley-invalid');
      else
        $form.addClass('parsley-invalid').removeClass('parsley-valid');
    })
  }
};

export default init;