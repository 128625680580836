// Equalizer Class to Data-Attribute converion
export default function () {
  const $allEqualizedItems = document.getElementsByClassName('equalizer-watch');
  if (typeof($allEqualizedItems) != 'undefined' && $allEqualizedItems != null) {
    var $item;
    for (var i = 0; i < $allEqualizedItems.length; i++) {
      $item = $allEqualizedItems[i];
      $item.setAttribute('data-equalizer-watch', '');
    }
  }
}

