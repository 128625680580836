import Isotope from "isotope-layout";
import $ from "jquery";

export default function () {

  // filter isotopeGrid on button click
  $('.isotope__filter-button-group').on('click', 'button', function() {
    var filterValue = $(this).attr('data-filter');
    $('.isotope__filter-button-group').find('.is-active').removeClass('is-active');
    $(this).addClass('is-active');
    iso.arrange({
      filter: filterValue
    });
  });
  $('.isotope__filter-dropdown').change(function() {
    var filterValue = $(this).val();
    iso.arrange({
      filter: filterValue
    });
  });


  let isotopeGrid = document.querySelector('.isotope-grid');
  if (isotopeGrid) {
    var iso = new Isotope(isotopeGrid, {
      // options
      layoutMode: 'packery',
      percentPosition: true,
      itemSelector: '.isotope-grid__item',
    });
  }
}
