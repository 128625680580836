import $ from "jquery";

export default function () {
  const _onScroll = () => {
    var distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 50,
      header = $('header');
    if (distanceY > shrinkOn) {
      header.addClass('smaller');
    } else {
      if (header.hasClass('smaller')) {
        header.removeClass('smaller');
      }
    }
    //scroll-top-arrow
    if (distanceY > 450) {
      $(".scroll-top-arrow").stop().fadeTo(400, 1);
    } else {
      $(".scroll-top-arrow").stop().fadeTo(400, 0);
    }
  }
  window.addEventListener('scroll', function(e) {
    window.requestAnimationFrame(_onScroll) // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame proper way to run scroll callbacks
  });
}