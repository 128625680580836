// costom ResponsiveTable
import ResponsiveTable from 'responsive-table';

export default ()=> {
  var $allResponsiveTable = document.getElementsByClassName('responsive_table');
  var breakPoint = 640;
  if (typeof ($allResponsiveTable) != 'undefined' && $allResponsiveTable != null) {
    var $item;
    for (var i = 0; i < $allResponsiveTable.length; i++) {
      $item = $allResponsiveTable[i];
      new ResponsiveTable.TableComponentController({
        breakPoint: breakPoint,
        element: $item
      });
    }
  }
};